import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Aboutus from "./pages/aboutus";
import Products from './pages/Products';
import Companies from './pages/Companies';
import Contact from './pages/Contact';
import Certificates from './pages/Certificates';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css.map";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/color.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/jquery.fancybox.min.css";
import "./assets/css/nice-select.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/preloader";
import "./assets/js/contact";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
// import { NoAccess } from './pages/NoAccess';


function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/" exact element={<NoAccess />} /> */}
        <Route path="/" exact element={<Home />} />
        <Route path="/Aboutus" exact element={<Aboutus />} />
        <Route path="/Companies" exact element={<Companies />} />
        <Route path="/Products" exact element={<Products />} />
        <Route path="/Certificates" exact element={<Certificates />} />
        <Route path="/Contact" exact element={<Contact />} />
      </Routes>
        
    </>
  );
}

export default App;
